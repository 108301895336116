registerProcessor(
  'ArrangerProcessor',
  class ArrangerProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;
    lastClockTime = 0;

    sectionPosition = -1;
    position = -1;

    rampValue = 0;
    rampDelta = 0;

    sections: number[];

    constructor(options: AudioWorkletNodeOptions) {
      super(options);
      this.sections = options.processorOptions.sections;
      this.port.onmessage = ({ data: [type, value] }) => {
        switch (type) {
          case 'sections':
            this.sections = value;
            this.position =
              value.length === 0 ? 0 : this.position % value.length;
            break;
          default:
            throw new Error('unrecognized message');
        }
      };
    }

    process(
      _: Float32Array[][],
      [[trigger], [ramp]]: Float32Array[][],
      { clock: [clock] }: { [param: string]: Float32Array },
    ): boolean {
      if (this.lastClock <= 0 && clock > 0) {
        const clockDelta = currentTime - this.lastClockTime;

        this.lastClockTime = currentTime;
        this.position = this.position + 1;

        if (
          this.position >= this.sections[this.sectionPosition] ||
          this.sectionPosition < 0
        ) {
          this.position = 0;
          this.sectionPosition =
            (this.sectionPosition + 1) % this.sections.length;
          this.rampValue = 0;
          this.port.postMessage(['position', this.sectionPosition]);
          trigger[0] = 1;
        }

        this.rampDelta =
          clockDelta === 0
            ? 0
            : (1 - this.rampValue) /
              (clockDelta *
                sampleRate *
                (this.sections[this.sectionPosition] - this.position));
      }

      this.lastClock = clock;

      for (let i = 0; i < ramp.length; i++) {
        ramp[i] = this.rampValue;
        this.rampValue += this.rampDelta;
      }

      return true;
    }
  },
);
